// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-my-product-js": () => import("./../../../src/pages/my-product.js" /* webpackChunkName: "component---src-pages-my-product-js" */),
  "component---src-pages-my-products-js": () => import("./../../../src/pages/my-products.js" /* webpackChunkName: "component---src-pages-my-products-js" */),
  "component---src-pages-my-project-js": () => import("./../../../src/pages/my-project.js" /* webpackChunkName: "component---src-pages-my-project-js" */),
  "component---src-pages-my-projects-js": () => import("./../../../src/pages/my-projects.js" /* webpackChunkName: "component---src-pages-my-projects-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-shopping-list-js": () => import("./../../../src/pages/shopping-list.js" /* webpackChunkName: "component---src-pages-shopping-list-js" */),
  "component---src-pages-terms-of-sales-js": () => import("./../../../src/pages/terms-of-sales.js" /* webpackChunkName: "component---src-pages-terms-of-sales-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

